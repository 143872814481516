<template>
    <div class="row table-sm mr-0 ml-0 p-0">
        <div class="crm-content-header d-flex w-100 mb-2">
            <div class="crm-content-header-left d-flex w-50">
                <div class="crm-content-header-left-item mr-3">
                    <h5 class="d-inline mr-2 font-weight-bold  theme-color">
                        {{ $t("message.main") }}
                    </h5> 
                </div>
            </div>
            <div class="crm-content-header-right d-flex w-50 justify-content-end">
                <el-date-picker
                    v-model="filterForm.from_date"
                    type="date"
                    size="mini"
                    :placeholder="$t('message.begin_date')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>

                <el-date-picker
                    v-model="filterForm.to_date"
                    type="date"
                    size="mini"
                    class="ml-2"
                    :placeholder="$t('message.end_date')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </div>
        </div>
        
        <!-- Call Counts Of the selected period-->
        <el-row :gutter="20" class="w-100">
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(stat, index) in totalStatistics" :key="'stat_'+index">
                <div class="card2" :class="getClass(index)">
                    <div class="card-body">
                        <div class="px-3">
                            <div class="row text-white">
                                <div class="col-8" style="align-items: center; justify-content: center;">
                                    <h4 class="text-white mb-2">{{$t('message.'+index)}}</h4>
                                </div>
                                <div class="col-4 text-right pl-0">
                                    <h4 class="pt-1 m-0 text-white">
                                        <span class="d-block mb-2"><span class="mr-2">{{ stat.total }}</span><i class="fa-solid fa-phone-volume w-32"></i></span>
                                        <span class="d-block mb-2"><span class="mr-2">{{ stat.incoming }}</span><i class="fa-solid fa-phone-arrow-down-left w-32"></i></span>
                                        <span class="d-block"><span class="mr-2">{{ stat.outgoing }}</span><i class="fa-solid fa-phone-arrow-up-right w-32"></i></span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <div id="chart" class="w-100 p-3 mytable bg-color" style="height: 485px">
            <div class="d-flex justify-content-between">
                <h5> {{ $t('message.Call counts by Messengers')}} </h5>
                <div>
                    <el-radio-group v-model="radioMessengers" size="mini" :disabled="(filterForm.from_date || filterForm.to_date) ? true : false">
                        <el-radio-button value="weekly" label="weekly">{{ $t('message.weekly') }}</el-radio-button>
                        <el-radio-button value="monthly" label="monthly">{{ $t('message.monthly') }}</el-radio-button>
                        <el-radio-button value="yearly" label="yearly">{{ $t('message.yearly') }}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <apexchart type="bar" height="435" :options="optionMessangers" :series="messagenger_series"></apexchart>
        </div>

        <div id="chart" class="w-100 p-3 mytable bg-color" style="height: 485px">
            <div class="d-flex justify-content-between">
                <h5> {{ $t('message.Call counts by Managers') }} </h5>
                <div>
                    <el-radio-group v-model="radioManagers" size="mini" :disabled="(filterForm.from_date || filterForm.to_date) ? true : false">
                        <el-radio-button value="weekly" label="weekly">{{ $t('message.weekly') }}</el-radio-button>
                        <el-radio-button value="monthly" label="monthly">{{ $t('message.monthly') }}</el-radio-button>
                        <el-radio-button value="yearly" label="yearly">{{ $t('message.yearly') }}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <apexchart type="bar" height="435" :options="optionManagers" :series="manager_series"></apexchart>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import VueApexCharts from "vue-apexcharts";

export default {
    name: "callDashboard",
    components: {
        VueApexCharts
    },

    data() {
        return {
            sipAccountList: [],
            filterForm: {
                from_date: '',
                to_date: '',
            },
            
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    categories: [],
                },
                legend: {
                    position: 'top',
                    colors: ['#00C71E', '#F93131']
                },
                fill: {
                    opacity: 1,
                },
                colors: ['#00C71E', '#F93131']
            },

            messagenger_series: [
                {
                    name: this.$t('message.accepted'),
                    data: []
                }, 
                {
                    name: this.$t('message.missed'),
                    data: []
                },
            ],

            manager_series: [
                {
                    name: this.$t('message.accepted'),
                    data: []
                }, 
                {
                    name: this.$t('message.missed'),
                    data: []
                },
            ],

            radioMessengers: '',
            optionMessangers: {},
            messangerCategories: ['Telegram', 'Instagram', 'TikTok', 'Facebook', 'WeChat', 'SnapChat'],

            radioManagers: '',
            optionManagers: {},
            managerCategories: ['Kamol', 'Bobur', 'Oybek', 'Azamat', 'Laziz', 'Bahodir'],
        };
    },

    created() {
        this.optionManagers = {...this.chartOptions, 
            xaxis: {
                categories: this.managerCategories,
            }
        }

        this.optionMessangers = {...this.chartOptions, 
            xaxis: {
                categories: this.messangerCategories,
            }
        }
    },

    computed: {
        ...mapGetters({
            list: "callHistories/list",
            columns: "callHistories/columns",
            authUser: "auth/user",
            permissions: "auth/permissions",
            mode: "MODE",
            serverSipAccountList: "sipAccounts/serverSipAccountList",
            totalStatistics: "callHistories/totalStatistics",
            trunckDiagrams: "callHistories/trunckDiagrams",
            userDiagrams: "callHistories/userDiagrams",
        }),
    },
    
    watch: {   
        filterForm: {
            handler: function(newVal, oldVal) {
                if (this.filterForm.from_date || this.filterForm.to_date) {
                    this.radioMessengers = '';
                    this.radioManagers = '';
                }
                this.fetchData();
            },
            deep: true,
            immediate: true
        },

        radioMessengers: {
            handler: function(newVal, oldVal) {
                this.fetchTrunckDiagrams();
            },
        },

        radioManagers: {
            handler: function(newVal, oldVal) {
                this.fetchUserDiagram();
            },
        },
    },

     methods: {
        ...mapActions({
            updateList: "callHistories/index",
            updateTotalStatistics: "callHistories/totalStatistics",
            updateTrunckDiagrams: "callHistories/trunckDiagrams",
            updateUserDiagrams: "callHistories/userDiagrams",
        }),

        getClass(name) {
            let color = 'bg-info2';
            if(name == 'answered'){
                color = 'bg-success2';
            }
            if(name == 'no_answer'){
                color = 'bg-danger2';
            }
            if(name == 'busy'){
                color = 'bg-danger2';
            }
            return color
        },
        
        async fetchData() {
            await this.updateTotalStatistics(this.filterForm);
            console.log(this.totalStatistics);
            this.fetchTrunckDiagrams();
            this.fetchUserDiagram();
        },

        fetchTrunckDiagrams() {
            this.updateTrunckDiagrams({...this.filterForm, ...{radioMessengers: this.radioMessengers}})
                .then(res => {
                    let phone_names = this.trunckDiagrams.map(el => el.name);
                    let answered = this.trunckDiagrams.map(el => el.answered_count);
                    let no_answered = this.trunckDiagrams.map(el => el.no_answer_count);
                    this.messagenger_series = [
                        {
                            name: this.$t('message.accepted'),
                            data: answered
                        },
                        {
                            name: this.$t('message.missed'),
                            data: no_answered
                        },
                    ];
                    this.optionMessangers = {...this.chartOptions, 
                        xaxis: {
                            categories: phone_names,
                        }
                    }
                })
                .catch(err => {
                    // console.log(err, 'errrr');
                });
        },

        fetchUserDiagram() {
            this.updateUserDiagrams({...this.filterForm, ...{radioManagers: this.radioManagers}})
                .then(res => {
                    let phone_names = this.userDiagrams.map(el => el.name);
                    let answered = this.userDiagrams.map(el => el.answered_count);
                    let no_answered = this.userDiagrams.map(el => el.no_answer_count);
                    this.manager_series = [
                        {
                            name: this.$t('message.accepted'),
                            data: answered
                        },
                        {
                            name: this.$t('message.missed'),
                            data: no_answered
                        },
                    ];
                    this.optionManagers = {...this.chartOptions, 
                        xaxis: {
                            categories: phone_names,
                        }
                    }
                })
                .catch(err => {
                    // console.log(err, 'errrr');
                });
        }
    },
    
};
</script>
<style lang="scss">

.w-32 {
    width: 32px;
}
// .card-body {
//   -ms-flex: 1 1 auto;
//   flex: 1 1 auto;
//   padding: 1.25rem;
// }
.card2 {
  border: 0;
  margin-top: 23px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  min-height: 120px;
}

.bg-success2 {
  background-image: -webkit-linear-gradient(45deg, #23bcbb, #45e994);
  background-image: -moz- oldlinear-gradient(45deg, #23bcbb, #45e994);
  background-image: -o-linear-gradient(45deg, #23bcbb, #45e994);
  background-image: linear-gradient(45deg, #23bcbb, #45e994);
  background-repeat: repeat-x;
}
.bg-danger2 {
  background-image: -webkit-linear-gradient(45deg, #ff5252, #f48fb1);
  background-image: -moz- oldlinear-gradient(45deg, #ff5252, #f48fb1);
  background-image: -o-linear-gradient(45deg, #ff5252, #f48fb1);
  background-image: linear-gradient(45deg, #ff5252, #f48fb1);
  background-repeat: repeat-x;
}
.bg-info2 {
  background-image: -webkit-linear-gradient(45deg, #0288d1, #26c6da);
  background-image: -moz- oldlinear-gradient(45deg, #0288d1, #26c6da);
  background-image: -o-linear-gradient(45deg, #0288d1, #26c6da);
  background-image: linear-gradient(45deg, #0288d1, #26c6da);
  background-repeat: repeat-x;
}
.bg-warning2 {
  background-color: #ff9149 !important;
  background-image: -webkit-linear-gradient(45deg, #e65100, #ffa000);
  background-image: -moz- oldlinear-gradient(45deg, #e65100, #ffa000);
  background-image: -o-linear-gradient(45deg, #e65100, #ffa000);
  background-image: linear-gradient(45deg, #e65100, #ffa000);
  background-repeat: repeat-x;
}

.mytable {
  background: #fff;
  border-radius: 0.5rem;
  // box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  margin-top: 25px;
}
</style>